import React, { useReducer } from "react"
import { useSpring } from "react-spring"

// 내부모듈
import EmailSignUpLayout from "../../components/emailSignUp/emailSignUpLayout"
import EmailSignUpForm from "../../components/emailSignUp/emailSignUp"
import { SendignEmail, Error } from "../../components/emailSignUp/resultUI"
import { css } from "@emotion/core"
import { IS_MOBILE } from "../../constants/env"

export type CertCodeStatus = "INIT" | "EXPIRED" | "VALID" | "INVALID" | "AGAIN"

export interface CertKey {
  certKeyid: string
  expiredTime: string
}

interface State {
  phoneNum: string
  isPhoneNumValid: boolean | null
  isRequestCertCode: boolean
  certCodeStatus: CertCodeStatus
  certCode: string
  email: string
  certKeyid: string
  isLoading: boolean
  isError: boolean
  certKey: CertKey | null
  isEmailValid: boolean | null
  index: number
  errorMessage: string
  marketingConsent: boolean
  reGetCertCodeCount: number
}

export interface Action {
  type:
    | "SET_PHONE_NUM"
    | "SET_EMAIL"
    | "SET_CERT_CODE"
    | "SET_CERT_KEY"
    | "SET_IS_REQUEST_CERT_CORD"
    | "SET_CERT_COED_STATUS"
    | "SEND_EMAIL"
    | "SET_CERT_KEY"
    | "FETCH_START"
    | "FETCH_END"
    | "FETCH_ERROR"
    | "SET_INDEX"
    | "SET_MARKETING_CONSENT"
    | "INCREASE_RE_GET_CERT_CODE_COUNT"
  payload?: any
}

const initialState: State = {
  phoneNum: "",
  isPhoneNumValid: null,
  isRequestCertCode: false,
  certCodeStatus: "INIT",
  certCode: "",
  email: "",
  certKeyid: "",
  isLoading: false,
  isError: false,
  certKey: null,
  isEmailValid: null,
  index: 0,
  errorMessage: "",
  marketingConsent: false,
  reGetCertCodeCount: 0,
}

export enum EmailSignUpIndex {
  signUp,
  sendingEmail,
  error,
}

const phoneNumValidator = (phoneNum: string) => {
  if (/^[0-9]+$/.test(phoneNum) && phoneNum.length === 11) {
    return true
  } else {
    return false
  }
}

const emailValidator = (email: string) => {
  const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/
  return exptext.test(email)
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "SET_PHONE_NUM":
      if (action.payload.length <= 11) {
        return {
          ...state,
          phoneNum: action.payload,
          isPhoneNumValid:
            action.payload.lenght === 0
              ? null
              : phoneNumValidator(action.payload),
        }
      } else {
        return state
      }
    case "SET_CERT_CODE":
      if (action.payload.length <= 6) {
        return { ...state, certCode: action.payload }
      } else {
        return state
      }
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
        isEmailValid:
          action.payload.length === 0 ? null : emailValidator(action.payload),
      }
    case "SET_IS_REQUEST_CERT_CORD":
      if (phoneNumValidator(state.phoneNum)) {
        return { ...state, isRequestCertCode: action.payload }
      } else {
        return { ...state, isPhoneNumValid: false }
      }
    case "SET_CERT_COED_STATUS":
      return {
        ...state,
        certCodeStatus: action.payload,
        certCode: action.payload === "INVALID" ? "" : state.certCode,
      }
    case "SEND_EMAIL":
      return state
    case "FETCH_START":
      return { ...state, isLoading: true }
    case "FETCH_END":
      return { ...state, isLoading: false }
    case "FETCH_ERROR":
      return {
        ...state,
        errorMessage: action.payload,
        isError: true,
        index: EmailSignUpIndex.error,
      }
    case "SET_CERT_KEY":
      return { ...state, certKey: action.payload }
    case "SET_INDEX":
      return { ...state, index: action.payload }
    case "SET_MARKETING_CONSENT":
      return { ...state, marketingConsent: action.payload }
    case "INCREASE_RE_GET_CERT_CODE_COUNT":
      const nextCount = state.reGetCertCodeCount + 1
      if (nextCount <= 3) {
        return { ...state, reGetCertCodeCount: state.reGetCertCodeCount + 1 }
      } else {
        return {
          ...state,
          index: EmailSignUpIndex.error,
          isError: true,
          errorMessage: "인증문자 요청 횟수를 초과했습니다",
        }
      }
    default:
      return state
  }
}

function MembersSignUp() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const firstAnimation = useSpring({
    from: { opacity: 0, transform: "translate3d(0,100%,0)" },
    to: { opacity: 1, transform: "translate3d(0,0,0)" },
  })

  const pages = [
    <EmailSignUpForm state={state} dispatch={dispatch} />,
    <SendignEmail />,
    <Error message={state.errorMessage} />,
  ]

  return IS_MOBILE ? (
    <div css={mobile__container}>{pages[state.index]}</div>
  ) : (
    <EmailSignUpLayout animation={firstAnimation}>
      {pages[state.index]}
    </EmailSignUpLayout>
  )
}

const mobile__container = css`
  height: 100vh;
  padding: 0px;
`

export default MembersSignUp
