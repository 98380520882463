// 외부모듈
import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import { css } from "@emotion/core"

// 내부모듈
import { mobileWidth } from "../../constants/css.json"

// 타입
import { Action } from "../../containers/emailSignUp"

interface MarketingConsentProps {
  marketingConsent: boolean
  dispatch: React.Dispatch<Action>
}

function MarketingConsent({
  marketingConsent,
  dispatch,
}: MarketingConsentProps) {
  const onChangeHandler = () => {
    dispatch({ type: "SET_MARKETING_CONSENT", payload: !marketingConsent })
  }

  return (
    <MarketingConsentScreen
      marketingConsent={marketingConsent}
      onChangeHandler={onChangeHandler}
    />
  )
}

interface MarketingConsentScreenProps {
  marketingConsent: boolean
  onChangeHandler: () => void
}

function MarketingConsentScreen({
  marketingConsent,
  onChangeHandler,
}: MarketingConsentScreenProps) {
  return (
    <div css={container}>
      <Checkbox
        color="primary"
        value={marketingConsent}
        onChange={onChangeHandler}
      />
      <p>마케팅 정보 제공 동의 (선택)</p>
    </div>
  )
}

const container = css`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-family: Spoqa Han Sans;
    font-size: 13px;
    line-height: 1.62;
    text-align: left;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #2d5bff !important;
  }
`

export default MarketingConsent
