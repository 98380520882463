// 외부모듈
import React, { useCallback } from "react"
import { css } from "@emotion/core"
import Button from "@material-ui/core/Button"
import axios, { AxiosError } from "axios"
import * as CryptoJs from "crypto-js"

// 내부모듈
import createTemplate from "../../template/authEmail"
import { API } from "../../constants/api"
import { secretKey } from "../../../keys/key.json"
import { mobileWidth } from "../../constants/css.json"

// 리소스
import emailIcon from "../../images/emailIcon.png"

// 타입
import {
  Action,
  CertCodeStatus,
  EmailSignUpIndex,
} from "../../containers/emailSignUp/index"
import MarketingConsent from "./marketingConsent"

interface EmailFormProps {
  isLoading: boolean
  certCodeStatus: CertCodeStatus
  dispatch: React.Dispatch<Action>
  email: string
  isPhoneNumValid: boolean | null
  isEmailValid: boolean | null
  phoneNum: string
  marketingConsent: boolean
}

function EmailForm(props: EmailFormProps) {
  const {
    email,
    dispatch,
    certCodeStatus,
    isEmailValid,
    phoneNum,
    isLoading,
    marketingConsent,
  } = props

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch({ type: "SET_EMAIL", payload: e.target.value })

  const sendEmailAuth = async () => {
    const encryptPhoneNum = CryptoJs.AES.encrypt(phoneNum, secretKey).toString()
    const encryptEmail = CryptoJs.AES.encrypt(email, secretKey).toString()

    const body = {
      subject: "플랜즈 커피 멤버십 가입 인증메일",
      to: email,
      html: createTemplate({
        phoneNum: encodeURIComponent(encryptPhoneNum),
        email: encodeURIComponent(encryptEmail),
      }),
      phoneNum,
      marketingConsent,
    }

    const res = await axios
      .post(API.sendEmail, body)
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          dispatch({
            type: "FETCH_ERROR",
            payload: `status:${err.code} message: ${err.message}`,
          })
        } else {
          dispatch({ type: "FETCH_ERROR", payload: err.message })
        }
      })

    return res
  }

  const onSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (certCodeStatus !== "VALID" || !isEmailValid) return
      if (isLoading) return

      dispatch({ type: "FETCH_START" })
      const res = await sendEmailAuth()

      if (res && res.data.result === true) {
        dispatch({ type: "SET_INDEX", payload: EmailSignUpIndex.sendingEmail })
      } else {
        // @ts-ignore
        dispatch({ type: "FETCH_ERROR", payload: res.data.message })
      }

      dispatch({ type: "FETCH_END" })
    },
    [certCodeStatus, isEmailValid, email, phoneNum, marketingConsent, isLoading]
  )

  return (
    <EmailFormScreen
      onChangeHandler={onChangeHandler}
      email={email}
      onSubmitHandler={onSubmitHandler}
      certCodeStatus={certCodeStatus}
      isEmailValid={isEmailValid}
      marketingConsent={marketingConsent}
      dispatch={dispatch}
    />
  )
}

interface EmailFormScreenProps {
  email: string
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmitHandler: (e: React.FormEvent<HTMLFormElement>) => void
  certCodeStatus: CertCodeStatus
  isEmailValid: boolean | null
  marketingConsent: boolean
  dispatch: React.Dispatch<Action>
}

function EmailFormScreen(props: EmailFormScreenProps) {
  const {
    onChangeHandler,
    onSubmitHandler,
    email,
    certCodeStatus,
    isEmailValid,
    marketingConsent,
    dispatch,
  } = props

  const isActive = certCodeStatus === "VALID" && isEmailValid

  return (
    <form css={email__form} onSubmit={onSubmitHandler}>
      {isEmailValid === false ? (
        <p css={invalid__text}>올바른 이메일 형식을 입력해 주세요</p>
      ) : (
        <p>회사 이메일</p>
      )}
      <div css={form__box}>
        <input
          type="email"
          placeholder="이메일을 입력해 주세요"
          onChange={onChangeHandler}
          value={email}
        ></input>
        <MarketingConsent
          marketingConsent={marketingConsent}
          dispatch={dispatch}
        />
        <Button
          variant="contained"
          type="submit"
          css={email__btn}
          color="primary"
          style={{
            borderRadius: "28px",
            marginTop: "5px",
            backgroundColor: isActive ? "#2d5bff" : "#8e8e8e",
          }}
        >
          <img src={emailIcon} css={email__icon} />
          이메일로 인증 링크 보내기
        </Button>
      </div>
    </form>
  )
}

const email__form = css`
  @media only screen and (max-width: ${mobileWidth}) {
    width: 90%;
    margin: 0 auto;
  }

  p {
    @media only screen and (max-width: ${mobileWidth}) {
      font-size: 13px;
    }
  }
`

const email__btn = css`
  margin-top: 5px;
  width: 410px;
  height: 56px;
  text-align: center;
  border-radius: 28px;
  color: #fff;
  background-color: #2d5bff;
  font-size: 15px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 101%;
    height: 46px;
  }
`

const email__icon = css`
  width: 27.6px;
  height: 27.6px;
  margin-right: 8.2px;
`

const invalid__text = css`
  color: #fe798b;
`

const input__style = css`
  margin-top: 17px;
`

const form__box = css`
  position: relative;
  margin-top: 17px;
`

export default EmailForm
