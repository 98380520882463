// 외부모듈
import React, { useCallback } from "react"
import { css } from "@emotion/core"
import Button from "@material-ui/core/Button"

// 내부모듈
import { mobileWidth } from "../../constants/css.json"
import { IS_MOBILE } from "../../constants/env"

// 타입
import { Action } from "../../containers/emailSignUp/index"

interface PhoneNumFormProps {
  dispatch: React.Dispatch<Action>
  isPhoneNumValid: boolean | null
  phoneNum: string
}

function PhoneNumForm(props: PhoneNumFormProps) {
  const { isPhoneNumValid, phoneNum, dispatch } = props

  const onSubmitHandler = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch({
      type: "SET_IS_REQUEST_CERT_CORD",
      payload: true,
    })
  }, [])

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: "SET_PHONE_NUM", payload: e.target.value }),
    []
  )

  return (
    <PhoneNumFormScreen
      onSubmitHandler={onSubmitHandler}
      onChangeHandler={onChangeHandler}
      isPhoneNumValid={isPhoneNumValid}
      phoneNum={phoneNum}
    />
  )
}

interface PhoneNumFormScreenProps {
  onSubmitHandler: (e: React.FormEvent<HTMLFormElement>) => void
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  isPhoneNumValid: boolean | null
  phoneNum: string
}

function PhoneNumFormScreen(props: PhoneNumFormScreenProps) {
  const { onChangeHandler, onSubmitHandler, isPhoneNumValid, phoneNum } = props
  return (
    <form css={phone__form} onSubmit={onSubmitHandler}>
      {isPhoneNumValid !== false ? (
        <p>전화번호</p>
      ) : (
        <p css={invalid__text}>올바른 전화번호 형식을 입력해주세요</p>
      )}
      <div css={form__box}>
        <input
          css={isPhoneNumValid === false && invalid__input}
          onChange={onChangeHandler}
          value={phoneNum}
          placeholder="- 을 제외한 전화번호를 입력해 주세요"
          required
          type="tel"
        ></input>
        <Button
          type="submit"
          color="primary"
          disabled={!isPhoneNumValid}
          style={{
            width: IS_MOBILE ? "80px" : "98px",
            height: IS_MOBILE ? "32px" : "38px",
            position: "absolute",
            top: "17%",
            right: IS_MOBILE ? "3%" : "10px",
            borderRadius: "28px",
            backgroundColor: isPhoneNumValid ? "#2d5bff" : "#707070",
            color: "#fff",
            fontSize: IS_MOBILE ? "11px" : "12px",
          }}
        >
          인증번호 발송
        </Button>
      </div>
    </form>
  )
}

const phone__form = css`
  position: relative;

  @media only screen and (max-width: ${mobileWidth}) {
    width: 90%;
    margin: 0 auto;
  }

  p {
    @media only screen and (max-width: ${mobileWidth}) {
      font-size: 13px;
    }
  }
`

const invalid__text = css`
  color: #fe798b;
`

const form__box = css`
  position: relative;
  margin-top: 17px;
`

const invalid__input = css`
  border: solid 1px #fe4d64 !important;
`

export default React.memo(PhoneNumForm)
