import React, { useCallback } from "react"
import { css } from "@emotion/core"

// 내부모듈
import PhoneNumForm from "./phoneNumForm"
import EmailForm from "./emailForm"
import CertCodeForm from "./certCodeForm"
import { mobileWidth } from "../../constants/css.json"
import { URL } from "../../constants/url"

function EmailSignUp({ state, dispatch }: any) {
  const {
    phoneNum,
    email,
    certCode,
    isRequestCertCode,
    isPhoneNumValid,
    isEmailValid,
    certCodeStatus,
    certKey,
    isLoading,
    marketingConsent,
    reGetCertCodeCount,
  } = state

  return (
    <div css={container}>
      <header css={header__style}>
        <h1>
          직장인을 위한 <br />
          작은 카페, 플랜즈커피!
        </h1>
        <h3>
          이메일로 우리회사 인증하고 <br /> 할인된 가격으로 다양한 음료를
          즐기세요!
        </h3>
      </header>
      <div css={upper__form}>
        {isRequestCertCode ? (
          <CertCodeForm
            certCode={certCode}
            certCodeStatus={certCodeStatus}
            phoneNum={phoneNum}
            certKey={certKey}
            dispatch={dispatch}
            isLoading={isLoading}
            reGetCertCodeCount={reGetCertCodeCount}
          />
        ) : (
          <PhoneNumForm
            dispatch={dispatch}
            isPhoneNumValid={isPhoneNumValid}
            phoneNum={phoneNum}
          />
        )}
      </div>
      <div css={below__form}>
        <EmailForm
          email={email}
          phoneNum={phoneNum}
          dispatch={dispatch}
          isLoading={isLoading}
          isEmailValid={isEmailValid}
          isPhoneNumValid={isPhoneNumValid}
          certCodeStatus={certCodeStatus}
          marketingConsent={marketingConsent}
        />
      </div>
      <footer css={footer__style}>
        <p>
          회원 가입시 <a href={URL.privacyTerm}>개인정보처리방침</a>과{" "}
          <a href={URL.serviceTerm}>회원약관</a>을 확인하였으며, 동의합니다.
        </p>
      </footer>
    </div>
  )
}

const container = css`
  height: 100%;
  position: relative;
  input {
    width: 380px;
    height: 22px;
    padding: 17px 0px 17px 28px;
    border-radius: 7px;
    border: solid 1px #707070;
    font-size: 15px;

    input:focus {
      border: solid 1px #2d5bff;
    }

    @media only screen and (max-width: ${mobileWidth}) {
      width: 98%;
      padding: 0px;
      padding-left: 2vw;
      height: 46px;
      font-size: 12px;
    }
  }
`

const header__style = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    margin-top: 62px;
    font-size: 30px;
    font-weight: bold;
    color: #414141;
    line-height: 1.4;
  }

  h3 {
    margin-top: 17px;
    font-size: 15px;
    color: #404040;
    line-height: 1.67;
  }
`

const upper__form = css`
  margin-top: 61px;
  position: relative;
`

const below__form = css`
  margin-top: 13px;
`

const footer__style = css`
  position: absolute;
  width: 100%;
  bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 13px;
  color: #414141;
  text-align: center;

  a {
    color: #405dff;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    font-size: 11px;
  }
`

export default EmailSignUp
