import { URL } from "../constants/url"

const createTemplate = ({
  phoneNum,
  email,
}: {
  phoneNum: string
  email: string
}) => `  
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Planz 이메일 인증</title>
  </head>
  <body style="padding: 0; margin: 0; border: none;">
    <div style="padding: 0; margin: 0; border: none; width: 100%;">
      <div>
        <div style="width: 800px; height: 160px; background-color: #242424;">
          <div style="height: 64.5px;"></div>
          <span style="display: inline-block; width: 58px;"></span>
          <img
            alt="logo"
            style="width: 22px; height: 16.5px;"
            src="https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/site%2Femail_logo.png?alt=media&token=281caf58-ff16-4276-9c90-cb11cff4e589"
          />
          <span style="display: inline-block; width: 32px;"></span>
          <span style="color: #ffffff; font-weight: normal; font-size: 26px;">
            <font>PLANZ COFFEE</font>
          </span>
        </div>
        <div style="padding: 20px 0px 0px 58px;">
          <h2>아래 인증 버튼을 클릭해주세요</h2>
          <p>
            아래의 인증 버튼을 클릭하시면 사원 인증및 <br />회원가입이
            완료됩니다
          </p>
          <div style="height: 15px;"></div>
          <p style="font-weight: bold;">인증 버튼</p>
          <div style="height: 20px;"></div>
          <button
            style="
              width: 200px;
              height: 60px;
              border-radius: 36px;
              background-color: #2d5bff;
              font-size: 23px;
              color: #ffffff;
              border: none;
            "
          >
            <a
              href=${URL.emailResult}?p=${phoneNum}&e=${email} 
              style="text-decoration: none; color: #ffffff; height: 100%;"
              ><font>인증하기</font></a
            >
          </button>
        </div>
        <div style="padding: 0px 0px 0px 58px;">
          <div style="height: 123px;"></div>
          <div style="width: 592px;">
            <div
              style="
                border-width: 1px;
                border-style: double none;
                border-color: #707070;
                height: 1px;
              "
            ></div>
            <div style="height: 23px;"></div>
            <font style="font-size: 16px; font-weight: lighter;">
              회원가입 관련 문의사항이 있으신가요?<br />
              본 메일은 발신전용으로, sales@planz-coffee.com으로 문의주시면
              답변드리겠습니다.<br />
              Copyright ⓒ PLANZ COFFEE All right reserved
            </font>
          </div>
        </div>
      </div>
    </div>
  </body>
</html> 
`
export default createTemplate
