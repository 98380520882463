// 외부모듈
import axios, { AxiosError } from "axios"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { css } from "@emotion/core"
import Button from "@material-ui/core/Button"

// 내부모듈
import {
  Action,
  CertCodeStatus,
  CertKey,
} from "../../containers/emailSignUp/index"
import { API } from "../../constants/api"
import { mobileWidth } from "../../constants/css.json"
import { IS_MOBILE } from "../../constants/env"

interface TimerProps {
  certCodeStatusHandler: (status: CertCodeStatus) => void
  reGetCertCode: () => void
}

function Timer(props: TimerProps) {
  const { certCodeStatusHandler, reGetCertCode } = props
  const [minutes, setMinutes] = useState(3)
  const [seconds, setSeconds] = useState(0)

  const onClickHandler = () => {
    setMinutes(3)
    setSeconds(0)
    reGetCertCode()
  }

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          certCodeStatusHandler("EXPIRED")
          clearInterval(countdown)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [minutes, seconds])

  return (
    <div css={timer__container}>
      <p>남은시간 </p>
      <p css={time__remaining__text}>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </p>
      <p css={cert__code__re__send} onClick={onClickHandler}>
        혹시 코드가 도착하지 않았나요? 재발송
      </p>
    </div>
  )
}

interface CertCodeFormProps {
  certCodeStatus: CertCodeStatus
  certCode: string
  phoneNum: string
  certKey: CertKey | null
  dispatch: React.Dispatch<Action>
  isLoading: boolean
  reGetCertCodeCount: number
}

function CertCodeForm(props: CertCodeFormProps) {
  const {
    certCode,
    certCodeStatus,
    phoneNum,
    certKey,
    dispatch,
    isLoading,
    reGetCertCodeCount,
  } = props

  useEffect(() => {
    dispatch({ type: "FETCH_START" })
    axios
      .post(API.getCertCode, { phoneNum })
      .then(res => {
        dispatch({ type: "SET_CERT_KEY", payload: res.data })
        dispatch({ type: "FETCH_END" })
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          dispatch({
            type: "FETCH_ERROR",
            payload: `status:${err.code} message: ${err.message}`,
          })
        } else {
          dispatch({ type: "FETCH_ERROR", payload: err.message })
        }
      })
  }, [])

  const reGetCertCode = () => {
    dispatch({ type: "INCREASE_RE_GET_CERT_CODE_COUNT" })
    if (reGetCertCodeCount > 3) return
    dispatch({ type: "FETCH_START" })
    axios
      .post(API.getCertCode, { phoneNum })
      .then(res => {
        dispatch({ type: "SET_CERT_KEY", payload: res.data })
        dispatch({ type: "FETCH_END" })
      })
      .catch((err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          dispatch({
            type: "FETCH_ERROR",
            payload: `status:${err.code} message: ${err.message}`,
          })
        } else {
          dispatch({ type: "FETCH_ERROR", payload: err.message })
        }
      })
  }

  const certCodeStatusHandler = useCallback(
    (status: CertCodeStatus) =>
      dispatch({ type: "SET_CERT_COED_STATUS", payload: status }),
    []
  )

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch({ type: "SET_CERT_CODE", payload: e.target.value }),
    []
  )

  const validateCertCode = async () => {
    if (certKey) {
      const res = await axios.post(API.validateCertCode, {
        phoneNum,
        certCode,
        certKeyId: certKey.certKeyid,
      })

      if (res.data.result === false) {
        dispatch({ type: "FETCH_ERROR", payload: res.data.message })
        return
      }

      return res.data
    }

    return { result: true, message: "INVALID" }
  }

  // 비동기 처리로직
  const onSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!isLoading) {
        dispatch({ type: "FETCH_START" })
        const data = await validateCertCode()
        dispatch({ type: "SET_CERT_COED_STATUS", payload: data.message })
        dispatch({ type: "FETCH_END" })
      }
    },
    [certCode]
  )

  const isValid = certCodeStatus === "VALID"
  const isInValid = certCodeStatus === "INVALID"
  const isExpired = certCodeStatus === "EXPIRED"

  return (
    <CertCodeFormScreen
      certCode={certCode}
      certCodeStatusHandler={certCodeStatusHandler}
      onChangeHandler={onChangeHandler}
      onSubmitHandler={onSubmitHandler}
      isValid={isValid}
      isInValid={isInValid}
      isExpired={isExpired}
      reGetCertCode={reGetCertCode}
    />
  )
}

interface CertCodeFormScreenProps {
  certCodeStatusHandler: (status: CertCodeStatus) => void
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmitHandler: (e: React.FormEvent<HTMLFormElement>) => void
  reGetCertCode: () => void
  isValid: boolean
  isInValid: boolean
  isExpired: boolean
  certCode: string
}

function CertCodeFormScreen(props: CertCodeFormScreenProps) {
  const {
    certCodeStatusHandler,
    certCode,
    isExpired,
    isInValid,
    isValid,
    onChangeHandler,
    onSubmitHandler,
    reGetCertCode,
  } = props

  const printTimer = () => {
    if (isExpired) {
      return <span css={invalid__text}>만료되었습니다</span>
    } else if (isValid) {
      return null
    } else {
      return (
        <>
          <Timer
            certCodeStatusHandler={certCodeStatusHandler}
            reGetCertCode={reGetCertCode}
          />
        </>
      )
    }
  }

  return (
    <form css={cert__code__form} onSubmit={onSubmitHandler}>
      <header>
        <p>인증문자</p>
        <div css={time__remaining}>{printTimer()}</div>
      </header>
      <div css={button__box}>
        <input
          placeholder={
            isInValid
              ? "인증코드를 다시 확인해주세요"
              : "인증 코드를 입력해 주세요"
          }
          onChange={onChangeHandler}
          required
          type="tel"
          value={certCode}
          disabled={isValid}
          css={[
            isExpired ? invalid__input : null,
            isInValid && invalid__placeholder,
          ]}
        ></input>
        {isValid ? (
          <div css={cert__valid}>인증완료</div>
        ) : (
          <Button
            disabled={isExpired || isValid}
            type="submit"
            color="primary"
            style={{
              position: "absolute",
              top: "17%",
              right: IS_MOBILE ? "3%" : "10px",
              width: IS_MOBILE ? "80px" : "98px",
              height: IS_MOBILE ? "32px" : "38px",
              borderRadius: "28px",
              border: "solid 1px #707070",
              color: "#707070",
              fontSize: IS_MOBILE ? "11px" : "12px",
            }}
          >
            확인
          </Button>
        )}
      </div>
    </form>
  )
}

const cert__code__form = css`
  font-family: Spoqa Han Sans;

  header {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: ${mobileWidth}) {
    width: 90%;
    margin: 0 auto;
  }

  p {
    @media only screen and (max-width: ${mobileWidth}) {
      font-size: 13px;
    }
  }
`

const time__remaining = css`
  font-family: Spoqa Han Sans;
  font-size: 12px;
  color: #2d5bff;
  margin-left: 12px;
  display: flex;
  align-items: center;
`

const time__remaining__text = css`
  @media only screen and (max-width: ${mobileWidth}) {
    font-size: 9px !important;
  }
`

const invalid__text = css`
  color: #fe798b;
`

const invalid__input = css`
  border: solid 1px #fe4d64 !important;
`

const invalid__placeholder = css`
  ::placeholder {
    color: #fe798b;
  }
`

const timer__container = css`
  height: 100%;
  display: flex;
  align-items: center;
`

const cert__valid = css`
  position: absolute;
  top: 17%;
  right: 10px;
  width: 98px;
  height: 38px;
  border-radius: 28px;
  background-color: #7694ff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  @media only screen and (max-width: ${mobileWidth}) {
    right: 3%;
    width: 80px;
    height: 32px;
    font-size: 11px;
  }
`

const button__box = css`
  position: relative;
  margin-top: 17px;
`

const cert__code__re__send = css`
  height: 100%;
  display: flex;
  align-items: center;
  font-family: Spoqa Han Sans;
  font-size: 12px;
  letter-spacing: normal;
  color: #414141;
  text-decoration: underline;
  margin-left: 21px;
  cursor: pointer;

  @media only screen and (max-width: ${mobileWidth}) {
    font-size: 9px !important;
  }
`

export default React.memo(CertCodeForm)
